import React from 'react';

import { useLocation } from '@reach/router';
import { clsx } from 'clsx';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import GLOBALS from 'app-globals';
import Container from 'components/Container';
import Heading from 'components/Heading';
import { HEADING_SIZES, HEADING_WEIGHTS } from 'components/Heading/constants';
import { useWindowSize } from 'hooks';
import CallToAction from 'widgets/CallToAction';

import DownloadBrochureButton from './DownloadBrochureButton';
import FooterSection from './Section';
import * as styles from './styles.module.scss';

const SECTIONS = [
  {
    title: 'Get in touch',
    items: [
      {
        icon: 'room',
        text: '753 Katipunan St., Tisa, Cebu City, Philippines',
      },
      {
        icon: 'phone',
        text: '+639062345128',
      },
      {
        icon: 'email',
        text: 'info@codechum.com',
      },
      {
        icon: 'link',
        text: (
          <a
            className={styles.link}
            href="https://codechum.zohobookings.com"
            rel="noreferrer"
            target="_blank"
          >
            Book a demo!
          </a>
        ),
      },
    ],
  },
  {
    title: 'Other links',
    items: [
      {
        text: (
          <Link className={styles.link} to="/privacy-policy">
            Privacy Policy
          </Link>
        ),
      },
    ],
  },
];

function Footer() {
  const { isMobile } = useWindowSize();
  const location = useLocation();
  const { pathname } = location;

  const isInHome = pathname === '/';

  return (
    <footer>
      <div
        className={clsx(styles.head, {
          [styles.head___home]: isInHome,
        })}
      >
        <Container>
          <div className={styles.head_content}>
            <div className={styles.message}>
              <div className={styles.message_text}>
                <Heading
                  className={styles.subheading}
                  colorClass={GLOBALS.COLOR_CLASSES.NEUTRAL['300']}
                  size={HEADING_SIZES.XS}
                  weight={HEADING_WEIGHTS.SEMIBOLD}
                >
                  DON'T GET LEFT BEHIND
                </Heading>
                <Heading className={styles.heading} size={HEADING_SIZES.LG}>
                  Join the Philippines' <br />{' '}
                  <span className={styles.heading___highlighted}>
                    <StaticImage
                      className={styles.highlight}
                      src="../../images/footer/highlight.svg"
                    />
                    most trusted{isMobile && ' platform'}
                    {isMobile && <br />}
                  </span>{' '}
                  {!isMobile && 'platform '}for programming education now!
                </Heading>
              </div>
              <div className={styles.actions}>
                <CallToAction size="small" />
                <DownloadBrochureButton />
              </div>
            </div>
            <StaticImage
              alt="Cody teaching programming"
              className="--desktop --tablet"
              src="../../images/footer/cody.png"
            />
          </div>
        </Container>
      </div>
      <Container>
        <div className={styles.info}>
          <div className={styles.branding}>
            <div className={styles.branding_text}>
              <StaticImage
                alt="CodeChum Logo"
                className={styles.branding_logo}
                layout="fullWidth"
                src="../../images/logo/long.inline.svg"
              />
              <Heading className={styles.tagline} size={HEADING_SIZES.XXS}>
                <span className={styles.tagline___blue}>
                  #1 trusted platform
                </span>{' '}
                for programming education
              </Heading>
            </div>
            <div className={styles.socials}>
              <a href="https://m.me/codechum" rel="noreferrer" target="_blank">
                <StaticImage
                  alt="Messenger"
                  className={styles.socials_image}
                  src="../../images/socials/messenger.png"
                />
              </a>
              <a
                href="https://www.facebook.com/codechum"
                rel="noreferrer"
                target="_blank"
              >
                <StaticImage
                  alt="Facebook"
                  className={styles.socials_image}
                  src="../../images/socials/fb.png"
                />
              </a>
            </div>
          </div>
          <div className={styles.separator} />
          {SECTIONS.map((section) => (
            <FooterSection
              key={section.title}
              items={section.items}
              title={section.title}
            />
          ))}
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
