import React, { useState, useEffect } from 'react';

import { useLocation } from '@reach/router';
import { clsx } from 'clsx';
import { Link } from 'gatsby';

import GLOBALS from 'app-globals';
import Container from 'components/Container';
import useBookCall from 'hooks/useBookCall';
import BookCallModal from 'widgets/BookCallModal';

import NavbarButtons from './Buttons';
import NavbarLinks from './Links';
import NavbarLogo from './Logo';
import UserTypeModal from './UserTypeModal';
import * as styles from './styles.module.scss';

const LINKS = [
  {
    text: 'Pricing',
    to: '/pricing',
  },
  {
    text: 'Tutorials',
    to: '/tutorials',
  },
  {
    text: 'About Us',
    to: '/about',
  },
];

function Navbar() {
  const location = useLocation();

  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isUserTypeModalOpen, setIsUserTypeModalOpen] = useState(false);

  const { bookCallProps, onBookCall } = useBookCall();

  useEffect(() => {
    document.addEventListener('scroll', () => {
      setIsScrolled(window.scrollY !== 0);
    });
  }, []);

  useEffect(() => {
    setIsMobileNavOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    const script1 = document.createElement('script');

    script1.type = 'text/javascript';
    script1.text = `
      window.$zoho=window.$zoho || {};
      $zoho.salesiq=$zoho.salesiq || {
        ready:function(){}
      }
    `;

    const script2 = document.createElement('script');

    script2.src =
      'https://salesiq.zohopublic.com/widget?wc=siqf20e5a6e53b939328a5a7837834e2fe8d8e5cb5c30723f79ba189059dea56191';
    script2.defer = true;
    script2.id = 'zsiqscript';

    document.body.appendChild(script1);
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script1);
      document.body.appendChild(script2);
    };
  }, []);

  // const backLink = (
  //   <div className={styles.back}>
  //     <Link to="/">
  //       <Icon colorName={GLOBALS.COLOR_NAMES.BLUE} name="arrow_back" />
  //     </Link>
  //     <Text colorClass={GLOBALS.COLOR_CLASSES.NEUTRAL['700']}>
  //       Back to CodeChum Website
  //     </Text>
  //   </div>
  // );

  const onMobileNavToggle = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <nav
      className={clsx(styles.navbar, {
        [styles.navbar___scrolled]: isScrolled,
        [styles.navbar___white]: location.pathname !== '/',
      })}
    >
      <BookCallModal {...bookCallProps} />

      {isUserTypeModalOpen && (
        <UserTypeModal
          onClose={() => {
            setIsUserTypeModalOpen(false);
          }}
        />
      )}
      <Container>
        <div className={styles.container}>
          <div className={styles.navigation}>
            <NavbarLogo />
            <NavbarLinks links={LINKS} />
          </div>
          <NavbarButtons
            isMobileNavOpen={isMobileNavOpen}
            isScrolled={isScrolled}
            onMobileNavToggle={onMobileNavToggle}
          />
        </div>
      </Container>

      <div
        className={clsx(styles.mobile, {
          [styles.mobile___open]: isMobileNavOpen,
        })}
      >
        <div className={styles.links___mobile}>
          <Link className={styles.link___mobile} to="/">
            Home
          </Link>
          {LINKS.map((link) => (
            <Link key={link.to} className={styles.link___mobile} to={link.to}>
              {link.text}
            </Link>
          ))}
        </div>
        <a
          className={styles.link___mobile}
          href={GLOBALS.LINKS.LOGIN}
          rel="noreferrer"
          target="_blank"
        >
          Log In
        </a>
        <a
          className={styles.link___mobile}
          href={GLOBALS.LINKS.SIGNUP}
          rel="noreferrer"
          target="_blank"
        >
          Sign Up
        </a>
        <button
          className={styles.link___mobile}
          type="button"
          onClick={onBookCall}
        >
          Book a Demo
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
