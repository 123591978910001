import React from 'react';

import clsx from 'clsx';

import useBookCall from 'hooks/useBookCall';
import BookCallModal from 'widgets/BookCallModal';

import * as styles from './styles.module.scss';

function CallToAction({ size }) {
  const { bookCallProps, onBookCall } = useBookCall();

  return (
    <>
      <BookCallModal {...bookCallProps} />
      <button
        className={clsx(styles.action, styles[`action___${size}`])}
        type="button"
        onClick={onBookCall}
      >
        Book a Demo
      </button>
    </>
  );
}

export default CallToAction;
