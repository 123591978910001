import React from 'react';

import GLOBALS from 'app-globals';
import { Button, Icon, IconButton } from 'components';
import { BUTTON_VARIANTS } from 'components/Button/constants';
import { ICON_SIZES } from 'components/Icon/constants';
import {
  ICON_BUTTON_SIZES,
  ICON_BUTTON_VARIANTS,
} from 'components/IconButton/constants';
import useBookCall from 'hooks/useBookCall';
import BookCallModal from 'widgets/BookCallModal';

import * as styles from '../styles.module.scss';

function NavbarButtons({ isMobileNavOpen, onMobileNavToggle }) {
  const { bookCallProps, onBookCall } = useBookCall();

  return (
    <>
      <BookCallModal {...bookCallProps} />

      <div className={styles.buttons}>
        <a
          className={styles.link___login}
          href={GLOBALS.LINKS.LOGIN}
          rel="noreferrer"
          target="_blank"
        >
          <Button
            colorName={GLOBALS.COLOR_NAMES.BLUE}
            variant={BUTTON_VARIANTS.SUBTLE}
          >
            Log In
          </Button>
        </a>
        <a
          className={styles.link___signup}
          href={GLOBALS.LINKS.SIGNUP}
          rel="noreferrer"
          target="_blank"
        >
          <Button
            colorName={GLOBALS.COLOR_NAMES.BLUE}
            variant={BUTTON_VARIANTS.SUBTLE}
          >
            Sign Up
          </Button>
        </a>
        <Button
          colorName={GLOBALS.COLOR_NAMES.GREEN}
          variant={BUTTON_VARIANTS.SOLID}
          onClick={onBookCall}
        >
          Book a Demo
        </Button>
      </div>
      <div className={styles.buttons___mobile}>
        <IconButton
          className={styles.toggle}
          colorName={GLOBALS.COLOR_NAMES.BLUE}
          icon={
            <Icon
              name={isMobileNavOpen ? 'close' : 'menu'}
              size={ICON_SIZES.LG}
            />
          }
          size={ICON_BUTTON_SIZES.MD}
          variant={ICON_BUTTON_VARIANTS.PLAIN}
          onClick={onMobileNavToggle}
        />
      </div>
    </>
  );
}

export default NavbarButtons;
