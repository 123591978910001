exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-challenge-jsx": () => import("./../../../src/pages/challenge.jsx" /* webpackChunkName: "component---src-pages-challenge-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-programming-education-insights-jsx": () => import("./../../../src/pages/programming-education-insights.jsx" /* webpackChunkName: "component---src-pages-programming-education-insights-jsx" */),
  "component---src-pages-sign-up-jsx": () => import("./../../../src/pages/sign-up.jsx" /* webpackChunkName: "component---src-pages-sign-up-jsx" */),
  "component---src-pages-tutorials-jsx": () => import("./../../../src/pages/tutorials.jsx" /* webpackChunkName: "component---src-pages-tutorials-jsx" */)
}

