import React from 'react';

import ReactModal from 'react-modal';

import * as styles from './styles.module.scss';

function BookCallModal({ isOpen, onClose }) {
  return (
    <ReactModal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      shouldFocusAfterRender
      shouldReturnFocusAfterClose
      className={styles.modal}
      isOpen={isOpen}
      overlayClassName={styles.overlay}
      parentSelector={() => document.querySelector('body')}
      onRequestClose={onClose}
    >
      <iframe
        allowFullScreen
        frameBorder="0"
        height="100%"
        src="https://codechum.zohobookings.com/portal-embed#/codechum-demo"
        title="Zoho Booking"
        width="100%"
      />
    </ReactModal>
  );
}

ReactModal.setAppElement('body');

export default BookCallModal;
