import { useState } from 'react';

const useBookCall = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onBookCall = () => {
    setIsModalOpen(true);
  };

  const bookCallProps = {
    isOpen: isModalOpen,
    onClose: () => {
      setIsModalOpen(false);
    },
  };

  return { bookCallProps, onBookCall };
};

export default useBookCall;
